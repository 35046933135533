<template>
    <v-container fluid>
        <page-banner links="Ma formation/Objectifs"></page-banner>

        <formation-header></formation-header>

        <v-row class="mt-5">
            <span class="text-h5">L'apprenant sera capable de :</span>
            <span class="text-h6 ml-2">(Aidez vous des listes ci-dessous pour déterminer le niveau de chacun de vos objectifs)</span>
        </v-row>

        <v-row class="my-8" style="position: sticky; z-index: 10; top: 50px">
            <v-col sm="4" md="2" v-for="(bloom, index) in bloomList" :key="index">
                <template>
                    <div class="text-center">
                        <v-menu offset-y max-height="500px">
                            <template v-slot:activator="{ on, attrs }">
                                <v-card tile elevation="5" class="lightgreen">
                                    <v-row dense>
                                        <v-col md="10">
                                            <v-card-text class="pa-0 text-uppercase font-weight-bold text-center">{{ bloom.name }}</v-card-text>
                                        </v-col>
                                        <v-col md="1">
                                            <v-card-actions class="pa-0">
                                                <v-btn icon x-small v-bind="attrs" v-on="on"><v-icon x-small>fas fa-chevron-down</v-icon></v-btn>
                                            </v-card-actions>
                                        </v-col>
                                    </v-row>
                                    <v-card tile width="100%" height="3px" elevation="0" :color="bloom.color"></v-card>
                                </v-card>
                            </template>
                            <v-list>
                                <v-list-item
                                    v-for="(item, i) in bloomList[index].words" :key="i"
                                    @click="copyBloom(item)"
                                >
                                    <v-list-item-title>{{ item }}</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </div>
                </template>
            </v-col>
        </v-row>

        <v-row class="mx-0 my-6">
            <v-col class="objective_list pa-0">
                <draggable @end="changePosition($event, 'educational')" handle=".handle" v-model="eduObjList">
                    <v-row v-for="eduObj in educationalObjectives" :key="eduObj.id">
                        <v-card width="100%" class="rounded-0">
                            <v-card-text>
                                <v-row class="fill-height">
                                    <v-col md="6" class="align-self-center">
                                        <custom-input
                                            type="educational"
                                            :objectId="eduObj.id"
                                            value-name="name"
                                            long-input
                                            elevation="0"
                                            placeholder="Nom de l'objectif"
                                            small
                                            bloom
                                            handle
                                        ></custom-input>
                                    </v-col>
                                    <v-col md="3" class="align-self-center">
                                        <custom-input
                                            type="educational"
                                            :objectId="eduObj.id"
                                            value-name="title"
                                            long-input
                                            elevation="0"
                                            placeholder="Titre de l'objectif"
                                            small
                                        ></custom-input>
                                    </v-col>
                                    <v-col md="1" offset="1"  class="align-self-center">
                                        {{ eduObj.learnerDuration | durationToHuman }}
                                    </v-col>
                                    <v-col md="1" class="text-right">
                                        <v-hover v-slot="{ hover }">
                                            <v-btn
                                                icon
                                                class="hoverBtn"
                                                :class="{ onHover: hover }"
                                                x-small
                                                @click.stop="
                                                dialogDelete = true;
                                                askForDelete = eduObj.id;
                                            ">
                                                <v-icon x-small>fas fa-times</v-icon>
                                            </v-btn>
                                        </v-hover>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-row>
                </draggable>
            </v-col>
        </v-row>

        <v-row class="mt-5">
            <v-btn icon @click="addEmptyEducationalObjective"><v-icon color="black">fas fa-plus</v-icon></v-btn>
        </v-row>


        <v-row class="mt-15" v-if="showTree">
            <v-card width="100%" class="purple lighten-1 pa-0" tile elevation="0" height="32px">
                <v-card-title class="text-h5 pa-0"><v-icon class="mx-5" color="purple">fas fa-sort-amount-down-alt</v-icon>Arbre des objectifs | L'apprenant sera capable de :</v-card-title>
            </v-card>
        </v-row>

        <v-row class="mt-10" v-if="showTree">
            <v-col>
                <v-row>
                    <v-col>
                        <v-row>
                            <v-col md="4" class="text-center">
                                <span class="text-h6">Objectifs pédagogiques</span>
                            </v-col>
                            <v-col md="4" class="text-center">
                                <span class="text-h6">Objectifs opérationnels</span>
                            </v-col>
                            <v-col md="4" class="text-center">
                                <span class="text-h6">Objectifs spécifiques (activités)</span>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="px-0">
                        <v-row v-for="eduObj in educationalObjectives" :key="eduObj.id" class="mb-6 border-bottom-big" dense>
                            <v-col md="4">
                                <custom-input type="educational" :objectId="eduObj.id" longInput :mutable="false" bloom></custom-input>
                            </v-col>
                            <v-col md="8">
                                <draggable @end="changePosition($event, 'operational', eduObj.id)" handle=".handle">
                                    <v-row
                                        v-for="opeObj in getOperationalObjectivesByParentId(eduObj.id)"
                                        :key="opeObj.id"
                                        class="mt-0 border-bottom mb-5"
                                        dense
                                    >
                                        <v-col md="6" class="py-0">
                                            <CustomInput type="operational" :objectId="opeObj.id" longInput removable bloom handle></CustomInput>
                                        </v-col>
                                        <v-col md="6" class="pl-4 py-0 mb-5">
                                            <draggable @end="changePosition($event, 'specific', opeObj.id)" handle=".handle">
                                                <v-row v-for="speObj in getSpecificObjectivesByParentId(opeObj.id)" :key="speObj.id" class="mt-0 mb-3 pr-4">
                                                    <CustomInput
                                                        type="specific"
                                                        :objectId="speObj.id"
                                                        :tooltip="false"
                                                        longInput
                                                        removable
                                                        bloom
                                                        handle
                                                    ></CustomInput>
                                                </v-row>
                                            </draggable>
                                            <v-row class="pr-4">
                                                <CreateInput type="Specific" :parentId="opeObj.id" label="Objectif spécifique" class="mb-5 mt-3"></CreateInput>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </draggable>
                                <v-row class="mb-2 row--dense">
                                    <v-col md="6" class=" py-0">
                                        <CreateInput type="Operational" :parentId="eduObj.id" label="Objectif opérationnel" class="mb-5 mt-1"></CreateInput>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>

        <next-page-button v-if="specificObjectives.length"></next-page-button>

        <v-dialog v-model="dialogDelete" max-width="400">
            <v-card>
                <v-card-title>Suppression </v-card-title>
                <v-card-text>Êtes vous sûr de vouloir supprimer cet objectif ainsi que tous ceux qui lui sont associés</v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="green darken-1"
                        text
                        @click="
                            dialogDelete = false;
                            askForDelete = null;
                        "
                    >
                        Annuler
                    </v-btn>

                    <v-btn
                        color="error darken-2"
                        text
                        @click="
                            dialogDelete = false;
                            deleteEducationalObjective();
                        "
                    >
                        Supprimer
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>

import CustomInput from "@/components/CustomInput";
import CreateInput from "@/components/CreateInput";
import PageBanner from "@/components/PageBanner";

import { mapActions, mapGetters, mapState } from "vuex";
import FormationHeader from "@/components/designer/FormationHeader";
import NextPageButton from "../../../components/NextPageButton";
import draggable from "vuedraggable";

export default {
    name: "Objectives",
    components: {
        NextPageButton,
        FormationHeader,
        CustomInput,
        CreateInput,
        PageBanner,
        draggable,
    },

    data: () => ({
        dialogDelete: false,
        askForDelete: null,
    }),

    computed: {
        ...mapState("formations", ["educationalObjectives", "currentFormation", "specificObjectives"]),

        ...mapGetters("formations", ["getOperationalObjectivesByParentId", "getSpecificObjectivesByParentId"]),

        ...mapState("global", ["bloomList"]),

        eduObjList: {
            get() {
                return this.educationalObjectives;
            },
            set(value) {
                this.$store.commit("formations/setEducationalList", value);
            }
        },
        
        bloomListFiltered: function() {
            const obj = this.bloomList.filter(x => x.name === this.category)[0];
            if(obj) {
                return obj.words;
            } else {
                return null;
            }
        },

        showTree: function() {
            return this.educationalObjectives.length >= 1 && this.educationalObjectives[0].name;
        }
    },

    methods: {
        ...mapActions("formations", ["setCurrentFormation", "addEmptyEducationalObjective", "removeEducationalObjective", "switchOrder"]),

        deleteEducationalObjective: function() {
            this.removeEducationalObjective(this.askForDelete);
            this.askForDelete = null;
        },

        copyBloom: function(bloom) {
            navigator.clipboard.writeText(bloom);
        },

        changePosition: function(event, type, parentId) {
            const { oldIndex, newIndex } = event;
            parentId = parentId || this.currentFormation;

            this.switchOrder({ type, formationId: this.currentFormation, parentId, oldIndex, newIndex });

            return true;
        }
    },
};
</script>

<style scoped>
.v-card__title {
    word-break: break-word;
}

.border-right {
    border-right: 1px solid rgba(166, 166, 166, 10);
}

.border-left {
    border-left: 1px solid rgba(166, 166, 166, 20);
}

.border-bottom {
    border-bottom: 2px solid rgb(166, 166, 166);
}

.border-bottom-big {
    border-bottom: 3px solid rgb(166, 166, 166);
}

.objective_list > div > .row{
    margin: 1px 0;
}

.bloom_menu .v-list-item-title {
    overflow-wrap: normal;
}
</style>
