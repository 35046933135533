<template>
    <v-card class="px-7" width="100%">
        <v-textarea
            ref="input"
            v-model="value"
            :placeholder="label"
            @blur="createElement"
            @keydown.enter.prevent="createElement"
            class="textStyleInput mt-0 py-4"
            auto-grow
            rows="1"
            hide-details
        ></v-textarea>
    </v-card>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
    name: "CreateInput",

    props: {
        type: {
            type: String,
            require: true,
        },
        parentId: {
            type: String,
            require: true,
        },
        label: {
            type: String,
        },
    },

    data: () => ({
        value: "",
    }),

    computed: {
        ...mapState("settings", ["user"]),
        ...mapState("formations", ["operationalObjectives", "specificObjectives"]),
    },

    methods: {
        ...mapActions("formations", ["addSpecificObjective", "addOperationalObjective"]),

        createElement() {
            if (this.value !== "") {
                if (this.type === "Operational") {
                    this.addOperationalObjective({ educationalObjectiveId: this.parentId, name: this.value });
                } else if (this.type === "Specific") {
                    this.addSpecificObjective({ operationalObjectiveId: this.parentId, name: this.value });
                }

                this.value = "";
            }
        },
    },
};
</script>

<style scoped>
.textStyleInput >>> .v-input__slot::before,
.textStyleInput >>> .v-input__slot::after {
    content: none;
}
</style>
