<template>
    <v-row class="d-flex flex-row-reverse mt-10">
        <v-card width="420px" height="40px" elevation="0" class="objective align-content-center rounded-lg" outlined @click="goToNextPage()">
            <v-card-title class="py-1 px-5">
                <v-row>
                    <v-col cols="11"><span class="text-h6">Aller vers la page "{{ nextRoute.meta.title }}"</span></v-col>
                    <v-col cols="1"><v-icon>fa fa-chevron-right</v-icon></v-col>
                </v-row>
            </v-card-title>
        </v-card>
    </v-row>
</template>

<script>
export default {
    name: "NextPageButton",

    computed: {
        title: function() {
            return this.$route.meta.title;
        },

        nextRoute: function() {
            return this.$router.options.routes.find(route => route.name === this.$route.meta.next);
        },
    },

    methods: {
        goToNextPage: function() {
            this.$router.push(this.nextRoute);
        }
    }
}
</script>

<style scoped>

</style>